<template>
    <div class="home" style="padding: 5px 27px;">

              
              <div class="col-lg-12 px-0">
                <div class="col-12 p-0 m-0 py-lg-2">
                      <div class="bg-neon mt1m ml1m mr1m d-none d-lg-block px-3 my-3">
                        <div class="row">
                          <div class="col-12 col-lg-3 p-0 m-0"> 
                            
                            <div class=" mb-0 p-lg-1">
                              <span class="d-block m-2" :class="{'txGold' : !$store.state.dark, 'text-white': $store.state.dark}">
                    <img src="../assets/sticon.png" style="width:20px;" alt="" class="d-inline-block" >
                    Presale Leaderboard
                  </span>
                            </div> 
                          </div>
                          <div class="col-12 col-lg-9 p-0 m-0">
                          <div class="mb-0 t8White p-lg-1 float-right" >
                            
                            <div  class="px-2 py-1">
                              <button class="btn-leader bg-neon ml-2 px-3 py-1" :class="{actives:perPage == 10}"  @click="perPage = 10" ><b>TOP 10</b></button>
                              <button class="btn-leader bg-neon ml-2 px-3 py-1" :class="{actives:perPage == 100}" @click="perPage = 100" ><b>TOP 100</b></button>
                              <button class="btn-leader bg-neon ml-2 px-3 py-1 mr-2" :class="{actives:perPage == 1000}" @click="perPage = 1000" ><b>TOP 1000</b></button>
                            
                            <input   type="text" name="" :class="{'text-white bg-dark': $store.state.dark}" v-model="search" style="width:300px;" 
                            placeholder="Enter Your Wallet" id=""  
                            class=" border-left-gold border-input">
                            <img v-if="!isSearch" src="@/assets/mag.svg" @click="makeSearch" 
                            
    style="    width: 19px;
    cursor: pointer;
    float: right;
    margin-left: -32px;
    position: relative;
    top: 5px;
    right: 7px;" alt="">
      <span v-else  style="width: 29px;
      cursor: pointer;
      float: right;
      margin-left: -23px;
      margin-top: 7px;
      position: relative;" alt="" @click="isSearch = false; search = ''"> <b>&#10006;</b> </span>
  
                          </div>
                          </div>
                        </div>
                      </div>
        </div>
        <div class="bg-neon p-md-2 d-lg-none my-4">
                        <div class="row p-0 m-0 ">
                          <div class="col-12 col-md-6 p-0 m-0"> 
                            <div class=" mb-0 t8White p-lg-3 pt-2 pt-md-1">
  
                              <button class="btn-leader bg-neon py-2" style="width:30%;margin-left:2.4%;" 
                              :class="{actives:perPage == 10}"  @click="perPage = 10" ><b>TOP 10</b></button>
                              <button class="btn-leader bg-neon py-2" style="width:30%;margin-left:2.4%;" 
                              :class="{actives:perPage == 100}" @click="perPage = 100" ><b>TOP 100</b></button>
                              <button class="btn-leader bg-neon py-2" style="width:30%;margin-left:2.4%;" 
                              :class="{actives:perPage == 1000}" @click="perPage = 1000" ><b>TOP 1000</b></button>
                            </div> 
                          </div>
                          <div class="col-12 col-md-6 p-0 m-0">
                            <div  
                            class="px-2  mt-2 m-md-0 py-1" style="width:400px;max-width: 99%;
      margin: 0% 0%;">
                            <input  type="text" name="" v-model="search" style="width:275px;" 
                            placeholder="Enter Your Wallet" id=""  class="border-left-gold border-input" :class="{'text-white bg-dark': $store.state.dark}">
                            <img v-if="!isSearch" src="@/assets/mag.svg" @click="makeSearch" style="    width: 19px;
    cursor: pointer;
    float: right;
    margin-left: -32px;
    position: relative;
    top: 11px;
    right: 7px;" alt="">  
      
      <span v-else  style="width: 29px;
      cursor: pointer;
      float: right;
      margin-left: -23px;
      margin-top: 7px;
      position: relative;" alt="" @click="isSearch = false; search = ''"> <b>&#10006;</b> </span>
                          </div>
                        </div>
                      </div>
        </div>
                     </div>
                      <div class="col-12 p-0 m-0" >
                      <!-- Total:{{promUsersFiltered.length}} Filterd <br>
                      Total:{{promUsers.length}} UnFilterd -->
                      <div class="position-relative" v-if="updating">
                      <p class="txGold float-right position-absolute"  style="top:-28px;">Updating......</p>
  
                      </div>
                      <div v-if="filteredUsersCalcs" id="mmtable">
                       <b-table 
                       sort-by="totalRewards" :sort-desc="true" sort-icon-right  tbody-class="bg-neon"  head-variant="bg-neon" :per-page="perPage" :current-page="currentPage"
                        responsive :items="filteredUsersCalcs" class="gold-table" :fields="prfields">
                        <!-- <template #head(uid)=""><span >Address</span></template> -->
                        <template #head(nftsCounter)=""><span >Ref Rights</span></template>
                        <!-- <template #head(verif)=""><span >Verified <br> Ref.</span></template> -->
                        <!-- <template #head(unverif)=""><span >Unverified <br> Ref.</span></template> -->
                        <template #head(rewards)="">VIP Points</template>
                        <template #head(badge)=""><span >Leader Score</span></template>
                        <!-- <template #head(esp)=""><span >Estimate <br> Prelaunch %</span></template> -->
                        <template #head(goldx)="">Estimated Yearly <br>GOLDX Mined</template>
                   
                   <template #cell(badge)="data"> <div v-html="data.item.badge">
  
                  </div> </template>
                  
                  <template #cell(wallet)="data"> 
                    <div class="d-none d-md-block link" @click="copyName(data.item.wallet)"> {{ data.item.wallet.slice(0, 8)+'.....'+data.item.wallet.slice(-8) }} </div> 
                    <div class="d-md-none link" @click="copyName(data.item.wallet)" :title="data.item.wallet"> {{ data.item.wallet.slice(0, 4)+'.....'+data.item.wallet.slice(-4) }} </div> 
                  </template>
                  <template #cell(rank)="data"> 
                  <span style="    display: block;
      padding-left: 32px;">
                    {{ (data.index + 1) }}  
                  </span>
                  </template>
                  <template #cell(nft)="data"> 
                  {{ data.item.nft }}  
                  </template>
                  <template #cell(nftsCounter)="data"> 
                  {{ data.item.nftsCounter }}  
                  </template>
                  <!-- <template #cell(rewards)="data"> 
                  {{ (data.item.rewards + data.item.buyRewards ) }}  
                  </template>   -->
                   <!-- <template #cell(kycverif)=""> 0 </template>   -->
                   <!-- <template #cell(acp)="">0.00% </template>   -->
                   <!-- <template #cell(acx)="">0 </template>   -->
  
                       </b-table>
                      </div>
                      <div v-else>
                        <img src="@/assets/loader.gif" class="d-block mx-auto" style="max-width:300px;" alt="" srcset="">
                      </div>
                      
                      
                  </div> 
  
                  <div class="col-lg-12 mt-3 mx-0 px-0">
               
              </div>
              
              </div>

    </div>
  </template>
  
  <script>
  export default {
    components:{
    },
    async mounted(){
      await this.fetchNFTs()
      await this.fetchUsers()
      // console.log()
      
      // await this.$store.dispatch("pullUsers")
    
    },
    methods:{
      
      copyName(link){
        navigator.clipboard.writeText(link)
        this.$root.Toast.fire({icon: "success",title:"Wallet Copied"})
      },
      copy(link)
    {
      navigator.clipboard.writeText(link)
        this.$root.Toast.fire({icon: "success",title:"Presale Address Copied"})
    },
      makeSearch(){
        if(this.search.length){
          this.isSearch = true
        }
        
      },
      fetchUsers(){
        this.$store.dispatch("getUsers")
      },
      async submit(){
        
        let priceUsd = ''
        let priceCrypto = ''
  
        if(this.slClass == 'Refiners'){
         priceCrypto = (this.refPrice / this.currentPrice[this.slCurr]) * this.slQty
         priceUsd = this.refPrice * this.slQty
        }else if(this.slClass == 'Miners'){
         priceUsd = this.minPrice * this.slQty
          priceCrypto = (this.minPrice / this.currentPrice[this.slCurr]) * this.slQty
        }else{
         priceUsd = this.prosPrice * this.slQty
          priceCrypto = (this.prosPrice / this.currentPrice[this.slCurr]) * this.slQty
        }
        
        let tx = {
          txHash: this.network+this.txHash,
          type: this.slClass,
          qty: this.slQty,
          priceUSD:priceUsd,
          isSelfmint:this.isSelfMint,
          priceCrypto:priceCrypto,
          crypto:this.slCurr,
          email: this.emailBuyer,
          refWallet:this.refWallet,
          refEmail:this.refEmail,
          refUsername:this.refUsername,
          userWallet:"",
        }
        console.log(tx)
        const isValidFormat = /^0x([A-Fa-f0-9]{64})$/.test(this.txHash);
  
  if (isValidFormat) {
    console.log('Valid transaction hash');
    let dec = await this.$store.dispatch("addPurchase",{tx})
        if(dec.status){
           this.qty = '';
           this.txHash = '';
           this.emailBuyer = '';
          //  this.showCharts = false
           this.$bvModal.hide('modal-xl')
           await this.fetchNFTs();
        this.$root.Toast.fire({icon: "success",title:"Success, Thank you"})
        }else{
        this.$root.Toast.fire({icon: "warning",title:dec.error})
        }
  } else {
    console.log('Invalid transaction hash');
    this.$root.Toast.fire({icon: "warning",title:"Invalid transaction hash"})
  
  }
        // return
        
      },
      async fetchNFTs(){
        this.$store.dispatch("getNFTs")
      },
      async validateReferral(wallet){
        let wl = await this.$store.dispatch("getRef", {wallet})
        console.log(wl)
        this.refEmail = (wl.email) ? wl.email : '';
        this.refWallet = wl.wallet;
        this.refUsername = wl.username
  
      },
      
      // async getMt() {
      //   return await axios.get("https://www.metals-api.com/api/latest?access_key=2cgcx34npsxd81ik1n166r3bqzj97yo1ljf748z95y6zr3ha1tw13ttcne4x")
      //   .then((res) => { 
      //     console.log('getMt',res)
      //     return res
      //   })
      // }
    },
    data(){
      return {
        refEmail:"",
        refWallet:"",
        showCharts:true,
        network:"https://bscscan.com/tx/",
        // filteredUsers:[],
        isSearch:false,
        refUsername:"",
        currs:['btc','eth','usdt','bnb','usdc','wbtc','dai','link'],
        emailReferrer:'',
        emailBuyer:"",
        slQty:0,
        txHash:"",
        slClass:"Miners",
        headerBgVariant: 'light',
          headerTextVariant: 'dark',
          bodyBgVariant: 'light',
          bodyTextVariant: 'dark',
          footerBgVariant: 'light',
          footerTextVariant: 'dark',
        slCurr:"ETH",
        totalAmount:1250000,
        refPrice:25000,
        minPrice:100,
        prosPrice:5,
        refiners:5,
        miners: 5625,
        isSelfMint:false,
        prospectors:112500,
        updating:false,
        search:"",
        currentPage:1,
        perPage:10,
        prfields:[
          {key: 'wallet',label: 'Address',sortable: false,"class":"col-uid"},
          {key: 'rank',label: 'Rank',sortable: false,"class":"col-rank",sortByFormatted: false},
  
          {key: 'nftsCounter',label: 'Total Ref.',sortable: true, sortByFormatted: false,"class":"col-total"
        },
        {key: 'nft',label: 'Purchased Rights',sortable: true, sortByFormatted: false,"class":"col-total"
        },
          // {key: 'verif',label: 'Verified Ref.',sortable: true, sortByFormatted: true,"class":"col-verif"},
          // {key: 'unverif',label: 'Unverified Ref.',sortable: true, sortByFormatted: true,"class":"col-unverif"},
        // {key: 'esPoints',label: 'Points (Estimated)',sortable: true,"class":"col-epoints-le",
        //   sortByFormatted: false
        
        // },
        
          {key: 'totalRewards',label: 'VIP Points',sortable: true,"class":"col-esx-le",
          sortByFormatted: false,      
        },
        {key: 'usd',label: 'USD Rewards',sortable: true,"class":"col-esx-le",
          sortByFormatted: false,      
        },
        // {key: 'badge',label: 'Leader Score',sortable: false,"class":"col-badge"},
  
        ],
  
      }
    },
    watch:{
      slClass(){
        this.slQty = 1
      },
    },
    computed:{
      users(){
        let us = this.$store.state.users
        if(us){
          us.sort(function(b, a) {
    return a.rewards - b.rewards;
  });
  return us;
        }else{
          return []
        }
        
      },
      filteredUsers(){
        if(this.isSearch){
         let users = [];
        //  this.isSearch = true
         this.users.forEach(element => {
          if (element.wallet == this.search) {
            users.push(element)
          }
         });
     
         return users
        }else{
        // this.isSearch = false
        
          return this.users
        }
      },
      filteredUsersCalcs(){
        let users = this.filteredUsers;
        this.filteredUsers.forEach((element, index) => {
          // users[index].rewards = 0
          users[index].totalRewards = (element.rewards + element.buyRewards) 
        });
        return users;
      },
      minersSold(){
        return this.$store.state.miners
      },
        refinersSold(){
        return this.$store.state.refiners
      },
        prospectorsSold(){
        return this.$store.state.prospectors
      },
      maxQty(){
        let qty = 0
        if(this.slClass == "Refiners") qty = (this.refiners - this.refinersSold)
        if(this.slClass == "Miners") qty =  (this.miners - this.minersSold)
        if(this.slClass == "Prospectors") qty =  (this.prospectors - this.prospectorsSold)
  
        return qty
      },
      totalNFTs(){
        return (this.refiners + this.miners + this.prospectors)
      },
      amountRaised() {
        let ref = this.refPrice * this.refinersSold;
        let min = this.minPrice * this.minersSold;
        let pros = this.prosPrice * this.prospectorsSold;
        return (Number(ref) + Number(min) + Number(pros))
      },
      nftSold() {
        return (this.refinersSold + this.minersSold + this.prospectorsSold)
      },
      
    },
  }
  </script>
  <style>
  .link{
    cursor:pointer;
    text-decoration:underline;
  }
  .hs h5{
    font-size: 115%;
  }
  .hsm h5{
    font-size: 90%;
  }
  #dropdown-1__BV_toggle_{width: 100%;font-size: 13px; padding: 8px;}
  .ft5{
    font-size: 13px;
  }
  .b-text{
    font-weight: 600;
  }
  </style>